import { useState } from "react";
import Navbar from "./Components/Navbar";
import Herosection from "./Components/Herosection";
import About from "./Components/About";
import Projects from "./Components/Projects";
import Skills from "./Components/Skills";

import Education from "./Components/Education";
import Contact from "./Components/Contact";
import Mobile from "./Components/Mobile";

function App() {
  const [isDark, setIsDark] = useState(false);

  function toggleDarkMode() {
    setIsDark(!isDark);
  }

  return (
    <div className={`${isDark ? 'dark' : ''} relative`}>
      {/* Background Container */}
      <div className={`absolute inset-0 ${isDark ? 'bg-slate-950' : 'bg-white'}`}>
        {/* Gradient Background */}
        <div className="absolute inset-0 bg-[linear-gradient(to_right,#4f4f4f2e_1px,transparent_1px),linear-gradient(to_bottom,#4f4f4f2e_1px,transparent_1px)] bg-[size:14px_24px] [mask-image:radial-gradient(ellipse_60%_50%_at_50%_0%,#000_70%,transparent_100%)]"></div>
      </div>

   
      <div className="relative z-10 flex flex-col items-center min-h-screen">
      
        <section className="lg:hidden block w-full">
        <Mobile  isDark={isDark} toggleDarkMode={toggleDarkMode}></Mobile>
        </section>
        <Navbar isDark={isDark} toggleDarkMode={toggleDarkMode} />
        
        
        <Herosection />
        <section id="about" className="w-full flex items-center justify-center">
          <About />
        </section>
        <section id="projects">
          <Projects />
        </section>
        <section id="skills" className="w-full flex items-center justify-center">
          <Skills></Skills>
        </section>
        <section id="education" className="w-full flex items-center justify-center">
          <Education></Education>
        </section>
        <section id="contact" className="w-full flex items-center justify-center">
          <Contact></Contact>
        </section>
        
        
        
        
      </div>
    </div>
  );
}

export default App;
