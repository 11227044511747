import React from 'react'
import img from '../Assets/DALL·E 2024-09-21 18.51.00 - A highly detailed and realistic Pixar-style cartoon avatar of a man with a slightly darker medium brown skin tone, a slimmer face, wearing glasses, sh.webp'
import Typed from 'typed.js';

const Herosection = () => {
  const el = React.useRef(null);

  React.useEffect(() => {
    const isLargeScreen = window.matchMedia("(min-width: 1024px)").matches;

    const strings = isLargeScreen 
      ? ['Software Developer.', 'Web Developer.', 'Tech Enthusiast.'] 
      : ['Developer.', 'Tech Explorer.','Curious Mind.'];

    const typed = new Typed(el.current, {
      strings,
      typeSpeed: 70,
      loop: true
    });

    return () => {
      // Destroy Typed instance during cleanup to stop animation
      typed.destroy();
    };
  }, []);
  return (
    <div className='flex flex-col lg:flex-row gap-10 h-auto lg:h-[100vh]  items-center justify-center px-4 lg:px-0 md:mt-8 mt-10' id='hero'>
      
      {/* Text Content */}
      <div className="flex w-full lg:w-[650px] flex-col items-start p-6 lg:p-10 space-y-4 dark:text-white">
        <h1 className="text-4xl md:text-5xl lg:text-6xl font-extrabold">
          Hi, I'm <span className="text-indigo-400">Ayman.</span>
        </h1>
        <h2 className="text-3xl md:text-4xl lg:text-5xl font-kanit">
          I'm a <span className="text-blue-500 font-bold" ref={el}></span>
        </h2>
        <p className="text-base md:text-lg lg:text-xl font-mono">
          I'm an IT enthusiast passionate about software development and front-end design. With a solid grasp of data structures and expertise in languages like C++, I enjoy turning complex problems into simple, beautiful solutions. I thrive in fast-paced environments, constantly learning new technologies, and staying adaptable.
        </p>
        <p className="text-base md:text-lg lg:text-xl font-mono">
          Feel free to explore my work, and let’s connect!
        </p>
        <div className="flex w-full flex-row">
          <button className="relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-purple-600 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800">
            <span className="relative px-5 text-lg py-2.5 transition-all font-kanit ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
              <a href='https://www.linkedin.com/in/aymansalimk/'>
                Let's Connect
              </a>
            </span>
          </button>
        </div>
      </div>

      {/* Image Section */}
      <div className='w-64 h-64 md:w-80 md:h-80 lg:w-96 lg:h-96 flex-shrink-0'>
        <img src={img} className='rounded-full object-cover w-full h-full' alt="Ayman's Avatar" />
      </div>
    </div>
  );
}

export default Herosection;
