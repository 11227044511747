import React from 'react'
import img from '../Assets/20240921_212644.jpg'

const About = () => {
  return (
    <div className='w-full md:w-2/3 mt-12 md:mt-24 px-4'>
      <div className='flex flex-col md:flex-row items-center md:justify-between w-full'>
        <span className='dark:text-white font-kanit text-4xl md:text-6xl font-bold'>
          About
          <span className='text-blue-500 font-serif'>.</span>
        </span>
        <div className='w-full hidden md:block h-[1px] bg-slate-400'></div>
      </div>

      <div className='flex flex-col md:flex-row items-center gap-8 justify-center mt-12 md:mt-24'>
        <div className='w-full md:w-[60%]'>
          <p className='dark:text-white font-mono text-lg md:text-xl'>
            Outside of the technical world, I’m someone who thrives on creativity and problem-solving. 
            I love experimenting with new ideas and technologies, finding innovative ways to tackle challenges. 
            When I’m not coding, I’m exploring new design trends, staying active, or learning something new. 
            I believe in the power of collaboration and continuous learning, always seeking ways to grow 
            both personally and professionally. <br />I’m driven by curiosity and passion, which keeps me motivated 
            to deliver my best work. Let's connect and create something impactful!
          </p>
        </div>

        <div className='w-40 h-40 md:w-80 md:h-80 flex-shrink-0'>
          <img src={img} alt="Profile" className='rounded-full object-cover w-full h-full' />
        </div>
      </div>
    </div>
  )
}

export default About
