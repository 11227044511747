import React from 'react';

const Education = () => {
  return (
    <div className="w-2/3 mt-32 mx-auto  ">
      <div className="flex items-center mb-8">
        <span className="dark:text-white font-kanit text-6xl font-bold">
          Education<span className="text-blue-500 font-serif">.</span>
        </span>
        <div className="w-full h-[1px] bg-slate-400 dark:bg-white ml-4"></div>
      </div>
      
      <div className="space-y-8">
  {/* Education 1 */}
    <div className=" dark:bg-gray-800 p-6 border rounded-lg shadow-lg transition-transform transform hover:scale-105 hover:shadow-xl">
        <h3 className="text-2xl font-semibold dark:text-white">
        Bachelor of Technology in [Computer Science With Specialization in AIML]
        </h3>
        <p className="text-lg text-gray-500 dark:text-gray-400">SRMIST, Chennai</p>
        <p className="text-md text-gray-400 dark:text-gray-500">2020 - 2024</p>
        <ul className="list-disc pl-5 mt-3 text-gray-600 dark:text-gray-300">
        <li>CGPA: 9.28</li>
        <li>Relevant Coursework: Data Structures, Algorithms, Web Development , AI , ML </li>
        </ul>
    </div>
    
    {/* Education 2 */}
    <div className="bg-white border dark:bg-gray-800 p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105 hover:shadow-xl">
        <h3 className="text-2xl font-semibold dark:text-white">Senior Secondary</h3>
        <p className="text-lg text-gray-500 dark:text-gray-400">CBSE Board</p>
        <p className="text-md text-gray-400 dark:text-gray-500">2019</p>
        <ul className="list-disc pl-5 mt-3 text-gray-600 dark:text-gray-300">
        <li>Percentage: 76.8%</li>
        <li>Key Subjects: Physics, Chemistry, Mathematics</li>
        </ul>
    </div>

    {/* Education 3 */}
    <div className="bg-white border dark:bg-gray-800 p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105 hover:shadow-xl">
        <h3 className="text-2xl font-semibold dark:text-white">Secondary</h3>
        <p className="text-lg text-gray-500 dark:text-gray-400">CBSE Board</p>
        <p className="text-md text-gray-400 dark:text-gray-500">2017</p>
        <ul className="list-disc pl-5 mt-3 text-gray-600 dark:text-gray-300">
        <li>Percentage: 98%</li>
        <li>Key Subjects: Science, Mathematics, Social Studies</li>
        </ul>
    </div>
</div>

    </div>
  );
};

export default Education;
