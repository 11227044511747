import React from 'react'
import img1 from '../Assets/weather app.PNG'
import proj2 from '../Assets/tic tactoe.PNG'
import { FaGithub } from "react-icons/fa";

const Projects = () => {
  return (
    <div className='w-full flex items-center justify-center mt-16 sm:mt-32 px-4'>
      <div className='w-full sm:w-2/3 flex flex-col'>
        {/* Title Section */}
        <div className='flex flex-col sm:flex-row items-center gap-2 sm:gap-1'>
          <div className='hidden sm:block w-full h-[1px] bg-slate-400'></div>
          <span className='dark:text-white font-kanit text-4xl sm:text-6xl font-bold'>
            Projects<span className='text-blue-500 font-serif'>.</span>
          </span>
          <div className='w-full h-[1px] bg-slate-400 sm:hidden'></div>
        </div>

        {/* Cards Section */}
        <div className='mt-5 dark:text-white flex flex-col sm:flex-row sm:flex-wrap gap-6 justify-center'>
          {/* Project Card 1 */}
          <div className='w-full sm:w-[48%] md:w-[45%] lg:w-[40%] mb-10'>
            {/* Image Section */}
            <div className='bg-slate-500 rounded-lg'>
              <div className='px-4 sm:px-16 pt-5 sm:pt-10'>
                <img
                  src={img1}
                  className='rounded-md transition transform hover:rotate-2 hover:-translate-y-1 hover:translate-x-1 duration-300'
                  alt='Weather App'
                />
              </div>
            </div>
            {/* Description Section */}
            <div className='mt-5'>
              <div className='flex items-center gap-2'>
                <div className='text-lg font-kanit font-semibold'>
                  Weather.app
                </div>
                <div className='bg-slate-400 h-[1px] w-full'></div>
                <a href='https://github.com/Aymansalimk/Weather-app'>
                  <FaGithub size={30} />
                </a>
              </div>
              <div className='flex gap-1 font-kanit text-blue-500'>
                <span>HTML -</span>
                <span>CSS -</span>
                <span>JavaScript -</span>
                <span>OpenWeatherMap API</span>
              </div>
              <div>
                <p className='font-kanit'>
                  A sleek weather app using HTML, CSS, and JavaScript, powered
                  by the OpenWeatherMap API. It provides real-time weather
                  updates based on user location or city search.
                </p>
              </div>
            </div>
          </div>

          {/* Project Card 2 */}
          <div className='w-full sm:w-[48%] md:w-[45%] lg:w-[40%] mb-10'>
            {/* Image Section */}
            <div className='bg-slate-500 rounded-lg'>
              <div className='px-4 sm:px-16 pt-5 sm:pt-10'>
                <img
                  src={proj2}
                  className='rounded-md transition transform hover:rotate-2 hover:-translate-y-1 hover:translate-x-1 duration-300'
                  alt='Tic Tac Toe'
                />
              </div>
            </div>
            {/* Description Section */}
            <div className='mt-5'>
              <div className='flex items-center gap-2'>
                <div className='text-lg font-kanit font-bold'>Tic Tac Toe</div>
                <div className='bg-slate-400 h-[1px] w-full'></div>
                <a href=''>
                  <FaGithub size={30} />
                </a>
              </div>
              <div className='flex gap-1 font-kanit text-blue-500'>
                <span>HTML -</span>
                <span>CSS -</span>
                <span>JavaScript -</span>
                <span>Tailwind CSS</span>
              </div>
              <div>
                <p className='font-kanit'>
                  This Tic Tac Toe game allows two players to alternate placing
                  "X" and "O" on a 3x3 grid. The objective is to get three marks
                  in a row, column, or diagonal while blocking the opponent. A
                  "New Game" button resets the grid to start a new round.
                </p>
              </div>
            </div>
          </div>

          {/* Project Card 3 */}
          <div className='w-full sm:w-[48%] md:w-[45%] lg:w-[40%] mb-10'>
            {/* Image Section */}
            <div className='bg-slate-500 rounded-lg'>
              <div className='px-4 sm:px-16 pt-5 sm:pt-10'>
                <img
                  src={img1}
                  className='rounded-md transition transform hover:rotate-2 hover:-translate-y-1 hover:translate-x-1 duration-300'
                  alt='Paint App'
                />
              </div>
            </div>
            {/* Description Section */}
            <div className='mt-5'>
              <div className='flex items-center gap-2'>
                <div className='text-lg font-kanit font-bold'>Paint.app</div>
                <div className='bg-slate-400 h-[1px] w-full'></div>
                <FaGithub size={30} />
              </div>
              <div className='flex gap-1 font-kanit text-blue-500'>
                <span>Flutter -</span>
                <span>MUI -</span>
                <span>Python -</span>
                <span>FastAPI</span>
              </div>
              <div>
                <p className='font-kanit'>
                  A real-time coaching app for students learning to paint. This
                  app is my baby, designed and built on my own.
                </p>
              </div>
            </div>
          </div>

          {/* Additional Project Card (if needed) */}
          <div className='w-full sm:w-[48%] md:w-[45%] lg:w-[40%] mb-10'>
            {/* Image Section */}
            <div className='bg-slate-500 rounded-lg'>
              <div className='px-4 sm:px-16 pt-5 sm:pt-10'>
                <img
                  src={img1}
                  className='rounded-md transition transform hover:rotate-2 hover:-translate-y-1 hover:translate-x-1 duration-300'
                  alt='Paint App'
                />
              </div>
            </div>
            {/* Description Section */}
            <div className='mt-5'>
              <div className='flex items-center gap-2'>
                <div className='text-lg font-kanit font-bold'>Paint.app</div>
                <div className='bg-slate-400 h-[1px] w-full'></div>
                <FaGithub size={30} />
              </div>
              <div className='flex gap-1 font-kanit text-blue-500'>
                <span>Flutter -</span>
                <span>MUI -</span>
                <span>Python -</span>
                <span>FastAPI</span>
              </div>
              <div>
                <p className='font-kanit'>
                  A real-time coaching app for students learning to paint. This
                  app is my baby, designed and built on my own.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Projects
