import { MdDarkMode, MdOutlineLightMode } from "react-icons/md";
import { Link } from "react-scroll"; // Import Link from react-scroll
import '../index.css'; 
import { FaHome } from "react-icons/fa";



const Navbar = ({ isDark, toggleDarkMode }) => {
  return (
    <div className="w-full fixed z-50">
      <div className="w-full flex items-center justify-center relative">
        <div className="flex hover:cursor-pointer lg:block hidden bg-purple-gradient-opacity mt-5 w-3/5 px-10 py-5 border rounded-3xl shadow-glass">
          <div className='flex justify-between w-full items-center flex-wrap'>
            {/* Links to sections using react-scroll */}
            <div className="flex justify-evenly w-2/3">
              <Link
                to="hero" 
                smooth={true} 
                duration={500}
                className="hover:scale-125 duration-200 text-xl dark:text-white font-bold font-kanit cursor-pointer">
                <FaHome size={24} />
              </Link>
              <Link 
                to="about" 
                smooth={true} 
                duration={500}
                className="hover:scale-125 duration-200 text-xl dark:text-white font-bold font-kanit cursor-pointer"
              >
                ABOUT
              </Link>
              <Link 
                to="projects" 
                smooth={true} 
                duration={500}
                className="hover:scale-125 duration-200 text-xl dark:text-white font-bold font-kanit cursor-pointer"
              >
                PROJECTS
              </Link>
              <Link 
                to="skills" 
                smooth={true} 
                duration={500}
                className="hover:scale-125 duration-200 text-xl dark:text-white font-bold font-kanit cursor-pointer"
              >
                EXP.
              </Link>
            </div>
            {/* Resume Button */}
            <div className="flex items-center border-[1px] border-black dark:border-inherit px-2 dark:border py-1 rounded-lg text-xl dark:text-white font-bold font-kanit">
              <a 
                href="/resume.pdf"  // Update the path here
                download="Resume.pdf"  // Force the file to download
                className="hover:scale-110 duration-200"
              >
                MY RESUME
              </a>
            </div>
          </div>
        </div>

        {/* Separate Dark/Light Mode Toggle */}
        <div className="absolute right-0 top-5 lg:top-0">  {/* Always visible, independent of the navbar */}
          <button 
            onClick={toggleDarkMode} 
            className="py-2 px-1 rounded"
          >
            <div className="flex gap-0 items-center justify-center">
              <div className="flex gap-2 border dark:border-none px-2 py-1 rounded-2xl bg-purple-gradient dark:bg-none">
                <p className="text-black dark:text-white">Light</p>
                <MdOutlineLightMode size={25} className="text-black dark:text-white" />
              </div>
              <div className="flex gap-1 dark:border border-none px-3 rounded-2xl pt-[3px] pb-[3px] dark:bg-purple-gradient">
                <p className="text-black dark:text-white">Dark</p>
                <MdDarkMode size={24} className="text-black dark:text-white" />
              </div>
            </div>
          </button>
        </div>
      </div>
     
    </div>
  );
}

export default Navbar;


