import React, { useState } from 'react';
import { Link } from 'react-scroll';
import { CiMenuBurger } from 'react-icons/ci';
import { MdDarkMode, MdOutlineLightMode } from "react-icons/md";

const Mobile = ({ isDark, toggleDarkMode }) => {
    const [burger, setBurger] = useState(false);

    const burgerClick = () => {
        setBurger(!burger);
        console.log("Burger clicked, state:", burger); // Debugging check
    };

    return (
        <div className='flex justify-between w-full px-5 pt-3 fixed z-[9999] '>
            {/* Burger Menu */}
            <div className="relative dark:text-white z-[9999] mt-[6px] "> 
                {/* Burger Icon */}
                <CiMenuBurger
                    size={30}
                    className="cursor-pointer hover:text-gray-500 transition-colors duration-300"
                    onClick={burgerClick}
                />

                {/* Menu Links */}
                <div
                    className={`${
                        burger ? 'block' : 'hidden'
                    }  p-4 rounded-lg shadow-lg font-kanit absolute -top-6 flex items-center gap-3 left-5  w-40 space-y-3 z-[9999]`}
                >
                    {/* Add explicit text color */}
                    <Link
                        to="hero"
                        smooth={true}
                        duration={500}
                        className="pt-[11px] text-black dark:text-white hover:text-blue-500 transition-colors duration-300 " 
                    >
                        {/* Force text for testing */}
                        Home
                    </Link>
                    <Link
                        to="about"
                        smooth={true}
                        duration={500}
                        className=" text-black dark:text-white hover:text-blue-500 transition-colors duration-300"
                    >
                        About
                    </Link>
                    <Link
                        to="skills"
                        smooth={true}
                        duration={500}
                        className="block text-black dark:text-white hover:text-blue-500 transition-colors duration-300"
                    >
                        Exp.
                    </Link>
                </div>
            </div>

            {/* Dark Mode Toggle */}
            <div className="z-[9999] fixed top-3 -right-[3px]">
                <button 
                    onClick={toggleDarkMode} 
                    className="py-2 px-1 rounded"
                >
                    <div className="flex gap-0 items-center justify-center">
                        <div className="flex gap-2 border dark:border-none px-2 py-1 rounded-2xl bg-purple-gradient dark:bg-none">
                            <p className="text-black dark:text-white">Light</p>
                            <MdOutlineLightMode size={25} className="text-black dark:text-white" />
                        </div>
                        <div className="flex gap-1 dark:border border-none px-3 rounded-2xl pt-[3px] pb-[3px] dark:bg-purple-gradient">
                            <p className="text-black dark:text-white">Dark</p>
                            <MdDarkMode size={24} className="text-black dark:text-white" />
                        </div>
                    </div>
                </button>
            </div>
        </div>
    );
};

export default Mobile;
